.course-tabs-navigation {
  position: relative;
  border-bottom: solid 1px #eaeaea;

  .nav a,
  .nav button {
    &:hover {
      background-color: $light-400;
    }
  }

  .nav a {
    &:not(.active):hover {
      background-color: $light-400;
      border-bottom: none;
    }
  }

  .nav-bar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }

  .nav-menu {
    flex: 1;
  }

  .search-toggle {
    flex-grow: 0;
    text-align: right;
    white-space: nowrap;
    margin-bottom: 10px;
  }


}
